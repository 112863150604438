import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { downloadPathwayIchraAgeBandedTable } from 'actions/pathwayBlueprint/downloadPathwayIchraAgeBandedTable';
import {
    LIST_AGE_PREMIUM_RATIOS_ACTION,
    listAgePremiumRatios,
} from 'actions/pathwayBlueprint/listAgePremiumRatios';
import { GroupLevels } from 'api/generated/enums';
import { PathwayIchraAgeBandedTableDto } from 'api/generated/models';
import AgeContainer from 'components/AgeContainer';
import Button from 'components/Button';
import Skeleton from 'components/Skeleton';
import { groupLevelAbbreviations } from 'constants/groupLevels';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import { formatCurrency } from 'utilities/format';
import { downloadFileFromApi } from 'utilities/rhFile';

const MIN_AGE = 23;
const MAX_AGE = 64;
const DEFAULT_AGE_BAND = 5;
const eeAbbreviation = groupLevelAbbreviations[GroupLevels.EE] as string;
const esAbbreviation = groupLevelAbbreviations[GroupLevels.ES] as string;
const ecAbbreviation = groupLevelAbbreviations[GroupLevels.EC] as string;
const efAbbreviation = groupLevelAbbreviations[GroupLevels.EF] as string;

type ICoverageLevelInputs = {
    reimbursementRatio: number;
    sixtyFourReimbursement: number;
};

type IAgeBandsTableProps = {
    ageBand: number;
    ecCoverageLevelInputs: ICoverageLevelInputs;
    eeCoverageLevelInputs: ICoverageLevelInputs;
    efCoverageLevelInputs: ICoverageLevelInputs;
    esCoverageLevelInputs: ICoverageLevelInputs;
};

const AgeBandsTable = ({
    ageBand,
    ecCoverageLevelInputs,
    eeCoverageLevelInputs,
    efCoverageLevelInputs,
    esCoverageLevelInputs,
}: IAgeBandsTableProps) => {
    const dispatch = useThunkDispatch();
    const { agePremiumRatios, isLoading } = useSelector((state: AppStore) => ({
        agePremiumRatios: state.agePremiumRatios,
        isLoading: hasApiActivity(state, LIST_AGE_PREMIUM_RATIOS_ACTION),
    }));
    const [isDownloadingAgeBandedTable, setIsDownloadingAgeBandedTable] = useState(false);
    useEffect(() => {
        if (Object.keys(agePremiumRatios).length === 0) {
            dispatch(listAgePremiumRatios());
        }
    }, [agePremiumRatios, dispatch]);

    const ages: number[] = useMemo(() => {
        let ageBound = MAX_AGE - 1;
        const agesList = [MAX_AGE];
        while (ageBound >= MIN_AGE) {
            agesList.push(ageBound);
            ageBound -= ageBand > 0 ? ageBand : DEFAULT_AGE_BAND;
        }
        return agesList;
    }, [ageBand]);

    const calculateReimbursement = useCallback(
        (age: number, coverageLevelInputs: ICoverageLevelInputs) => {
            const { reimbursementRatio, sixtyFourReimbursement } = coverageLevelInputs;
            const defaultPremiumRatioForAge = agePremiumRatios[age] ?? 0;
            const scaledPremiumRatioForAge =
                ((defaultPremiumRatioForAge - 1) / 2) * (reimbursementRatio - 1) + 1;
            return (scaledPremiumRatioForAge * sixtyFourReimbursement) / reimbursementRatio;
        },
        [agePremiumRatios]
    );

    const getLabel = useCallback(
        (age: number): string => {
            if (age === MAX_AGE) {
                return `${MAX_AGE} and over`;
            }
            if (age === MIN_AGE) {
                return `${MIN_AGE} and under`;
            }
            if (ageBand !== 1) {
                return `${age + 1 - ageBand} - ${age}`;
            }
            return age.toString();
        },
        [ageBand]
    );

    const tableData = useMemo(
        () =>
            ages.map((age) => ({
                bandLabel: getLabel(age),
                ecAmount: calculateReimbursement(age, ecCoverageLevelInputs),
                eeAmount: calculateReimbursement(age, eeCoverageLevelInputs),
                efAmount: calculateReimbursement(age, efCoverageLevelInputs),
                esAmount: calculateReimbursement(age, esCoverageLevelInputs),
            })),
        [
            ages,
            calculateReimbursement,
            ecCoverageLevelInputs,
            eeCoverageLevelInputs,
            efCoverageLevelInputs,
            esCoverageLevelInputs,
            getLabel,
        ]
    ) as PathwayIchraAgeBandedTableDto[];

    const onDownloadClick = useCallback(async () => {
        setIsDownloadingAgeBandedTable(true);
        await downloadFileFromApi(
            async () => downloadPathwayIchraAgeBandedTable(tableData),
            'Failed to download ICHRA Age Banded Table'
        );
        setIsDownloadingAgeBandedTable(false);
    }, [tableData]);

    return (
        <Skeleton count={4} isEnabled={isLoading}>
            <AgeContainer gridTemplateColumns="repeat(5, 1fr)">
                <Typography>Age</Typography>
                <Typography>{eeAbbreviation}</Typography>
                <Typography>{esAbbreviation}</Typography>
                <Typography>{ecAbbreviation}</Typography>
                <Typography>{efAbbreviation}</Typography>
            </AgeContainer>
            <Divider sx={{ mb: 1 }} />
            <AgeContainer gridTemplateColumns="repeat(5, 1fr)">
                {tableData.map((rowData) => (
                    <React.Fragment key={rowData.bandLabel}>
                        <Typography key={rowData.bandLabel}>{rowData.bandLabel}</Typography>
                        <Stack alignItems="center" direction="row" gap={1} key={eeAbbreviation}>
                            <Typography>
                                {formatCurrency(rowData.eeAmount, { preserveDecimal: true })}
                            </Typography>
                        </Stack>
                        <Stack alignItems="center" direction="row" gap={1} key={esAbbreviation}>
                            <Typography>
                                {formatCurrency(rowData.esAmount, { preserveDecimal: true })}
                            </Typography>
                        </Stack>
                        <Stack alignItems="center" direction="row" gap={1} key={ecAbbreviation}>
                            <Typography>
                                {formatCurrency(rowData.ecAmount, { preserveDecimal: true })}
                            </Typography>
                        </Stack>
                        <Stack alignItems="center" direction="row" gap={1} key={efAbbreviation}>
                            <Typography>
                                {formatCurrency(rowData.efAmount, { preserveDecimal: true })}
                            </Typography>
                        </Stack>
                    </React.Fragment>
                ))}
            </AgeContainer>
            <Button
                color="secondary"
                isLoading={isDownloadingAgeBandedTable}
                onClick={onDownloadClick}
                sx={{ marginX: 'auto', marginY: 2, maxWidth: '16rem' }}
                variant="text"
            >
                Download Age-Banded Table
            </Button>
        </Skeleton>
    );
};

export default hot(module)(AgeBandsTable);
