import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { uploadCensusClearErrors } from 'actions/uploadCensusClearErrors';
import { UPLOAD_CENSUS_ACTION, uploadCensus } from 'actions/user/uploadCensus';
import { downloadCensusTemplate } from 'api/downloadCensusTemplate';
import { TeamStateIds } from 'api/generated/enums';
import Button from 'components/Button';
import DateTextField from 'components/DateTextField';
import FileInput from 'components/fileInput/FileInput';
import Form from 'components/Form';
import useForm from 'hooks/useForm';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useCallback, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';
import styled from 'styled-components';
import { hasValue } from 'utilities';
import { onChange } from 'utilities/forms';
import { downloadFileFromApi } from 'utilities/rhFile';
import { object, string } from 'yup';

const FULL_DATE_LENGTH = 10;

const ErrorContainer = styled.div`
    overflow: hidden;
`;

const schema = object({
    memberActiveDate: string()
        .when('$isInProspect', {
            is: false,
            then: string().required(),
        })
        .isValidDate(false)
        .label('Member Active Date'),
});

type IUploadCensusModalProps = {
    onClose: () => void;
    peopleUrl: string;
};

const acceptsFileTypes = ['.csv', '.xls', '.xlsx'];
const UploadCensusModal = ({ onClose: parentOnClose, peopleUrl }: IUploadCensusModalProps) => {
    const dispatch = useThunkDispatch();
    const { teamId, teamStateId } = useTeamProps();
    const { apiError, apiErrorMessage, isLoading, selectedYear } = useSelector(
        (state: AppStore) => ({
            apiError: !!state.apiErrors[UPLOAD_CENSUS_ACTION.statusCode],
            apiErrorMessage: (state.apiErrors[UPLOAD_CENSUS_ACTION.errorMessage] as unknown) as {
                errors: string[];
                identifier: string;
            }[],
            isLoading: hasApiActivity(state, UPLOAD_CENSUS_ACTION),
            selectedYear: state.profileState.selectedYear,
        })
    );
    const [census, setCensus] = useState<File | File[] | undefined>();
    const [memberActiveDate, setMemberActiveDate] = useState('');
    const { errors, validate } = useForm(schema);

    const onSubmit = useCallback(async () => {
        const { isValid } = await validate(
            { memberActiveDate },
            { context: { isInProspect: teamStateId === TeamStateIds.Prospect } }
        );
        if (isValid) {
            await dispatch(uploadCensus(teamId, census as File, memberActiveDate, peopleUrl));
        }
    }, [census, dispatch, memberActiveDate, peopleUrl, teamId, teamStateId, validate]);

    const onClose = useCallback(() => {
        dispatch(uploadCensusClearErrors());
        parentOnClose();
    }, [dispatch, parentOnClose]);

    const renderErrors = useCallback(
        () =>
            apiErrorMessage.map((x) => (
                <div className="ml-2" key={x.identifier}>
                    <Typography variant="h6">{x.identifier}</Typography>
                    <ul>
                        {x.errors.map((e, index) => (
                            <li key={index}>{e}</li>
                        ))}
                    </ul>
                </div>
            )),
        [apiErrorMessage]
    );

    const downloadCensusTemplateFromApi = useCallback(
        async () => downloadFileFromApi(downloadCensusTemplate),
        []
    );

    const disableSubmit = useMemo(() => !hasValue(census), [census]);

    return (
        <Modal backdrop="static" onHide={onClose} scrollable show>
            <Modal.Header closeButton>
                <Modal.Title>Import Census</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form id="upload-census-modal" isLoading={isLoading} onSubmit={onSubmit}>
                    <Stack gap={2}>
                        {teamStateId !== TeamStateIds.Prospect && (
                            <DateTextField
                                data-cy="census-upload-member-active-date"
                                errors={errors?.memberActiveDate}
                                helperText="This is the date that these members will start receiving benefits (you can change this later)."
                                label="Active Date for New Members"
                                name="memberActiveDate"
                                onChange={onChange(setMemberActiveDate)}
                                value={memberActiveDate}
                            />
                        )}
                        <FileInput
                            accept={acceptsFileTypes}
                            fileData={census}
                            label="Census File"
                            onChange={setCensus}
                        />
                        <a
                            className="text-primary"
                            download="CensusTemplate.xlsx"
                            onClick={downloadCensusTemplateFromApi}
                        >
                            Download Census Template
                        </a>
                    </Stack>
                    {apiError && (
                        <ErrorContainer>
                            <Typography color={(theme) => theme.palette.error.main} variant="h4">
                                Upload Errors
                            </Typography>
                            <p>
                                There were errors uploading the file. Please see the messages below,
                                update your file, and upload again.
                            </p>
                            <Errors>{renderErrors()}</Errors>
                        </ErrorContainer>
                    )}
                </Form>
            </Modal.Body>
            <Modal.Footer className="centered">
                <Stack gap={2}>
                    <Typography variant="body2">
                        Census will be uploaded for{' '}
                        {hasValue(memberActiveDate) && memberActiveDate.length === FULL_DATE_LENGTH
                            ? new Date(memberActiveDate).getFullYear()
                            : selectedYear}
                    </Typography>
                    <Stack direction="row" gap={2} justifyContent="center">
                        <Button onClick={onClose}>Close</Button>
                        <Button
                            disabled={disableSubmit}
                            form="upload-census-modal"
                            isLoading={isLoading}
                            type="submit"
                        >
                            Upload
                        </Button>
                    </Stack>
                </Stack>
            </Modal.Footer>
        </Modal>
    );
};

const Errors = styled.div.attrs(() => ({ className: 'border' }))`
    max-height: 200px;
    overflow-y: scroll;
`;

export default hot(module)(UploadCensusModal);
